import { Database2 } from '@styled-icons/remix-line';
import { Database2 as Database2Fill } from '@styled-icons/remix-fill';
import { Icon, IconProps } from './Icon';

export function DatabaseIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Database2 />
    </Icon>
  );
}

export function DatabaseFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Database2Fill />
    </Icon>
  );
}
